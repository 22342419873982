import React from "react";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Slider from "react-slick";
import "../css/Home.css";
import { useEffect } from "react";
import { useState } from "react";
import { URL1 } from "../config";
import { useSelector } from "react-redux";
import { FormatIndentDecreaseTwoTone } from "@mui/icons-material";

import Image from "../images/banner/BJF-Stars-2024-XII-Result.jpg";

const Home = () => {

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 1
            }
        }
    ]
  };

  const [annList, setannList] = useState([])
  const Data = useSelector(state => state)

  const [isOpened, setIsOpened] = useState(false);

  function toggle() {
    setIsOpened(wasOpened => !wasOpened);
  }

  async function fetchData() {
    try{
      let res = await fetch(URL1 + '/get_announcements' , {
        method : "GET" , 
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Data.auth["access_token"],
        },
      })
      let resJson = await res.json()
      let ar = resJson.data
      ar.sort((a,b)=> {
        if( a['an_id'] > b['an_id']){
          return -1
        }
        
        else{
          return 1
        }
      })
      console.log(ar)
      
      
      setannList(ar.filter(itm => itm.publish == 'yes'))
      

    }
    catch (err) {
      

    }
    
  }

useEffect(() => {
    fetchData()

  
}, [])

function formatDate(date) {
  var date = new Date(date)
  var dd = date.getDate();
  var mm = date.getMonth() + 1; //January is 0!

  var yyyy = date.getFullYear();
  if (dd < 10) {
      dd = '0' + dd;
  }
  if (mm < 10) {
      mm = '0' + mm;
  }
  date =  dd + "/" + mm + "/" + yyyy;
  return date.toString();
  
}


  return (
    <div className="home">

      <div className="row g-0">
      {/* <div className="col-12 col-xl-9 home-bg-img"> */}
        <div className="col-12 col-xl-9 spotH8Img" style={{ backgroundImage: "url(" + Image + ")" }}>
          <div className="bannerContSec">
            {/* <div className="text">
              <div>
                <p >Empowering Rural Students</p>
                <div>
                  <p className="inlinetext">
                    Subject Experts | Mentoring | Personalized Counselling
                  </p>
                </div>
              </div>
            </div> */}
            {/* <div className="searchbar d-flex">
              <div className="pe-2 col position-relative">
                <input type="text" placeholder="Search here" className="searchbar-input" />
              </div>              
              <div className="srchBtnW">
                <button type="button" className="search-button">Search</button>
              </div>
            </div> */}
          </div>
          {/* <div className="student-Qualified-Section row">
            <p className="student-qualified-text">
              Student Qualified between 2016-2022
            </p>
            <div className="col">
              <p className="qualified-section" >
                40
                <div>
                  <p>iit/iiit & iiser</p>
                </div>
              </p>
            </div>

            <div className="col">
              <p className="qualified-section" >
                58
                <div>
                  <p>NIT/PEC</p>
                </div>
              </p>
            </div>

            <div className="col">
              <p className="qualified-section" >
                46
                <div>
                  <p>AIIMS/Govt. Medical Colleges</p>
                </div>
              </p>
            </div>


            <div className="col">
              <p className="qualified-section" >
                830 +
                <div>
                  <p>JNV</p>
                </div>
              </p>
            </div>

            <div className="col">
              <p className="qualified-section" >
                40
                <div>
                  <p>IIT/NIT</p>
                </div>
              </p>
            </div>

          </div> */}
        </div>
        <div className="col-12 col-xl-3 side-content">
          <div className="px-4 pt-2 pb-4">
            <div className="pb-2 text-end">
              <button type="button" className="btn p-0 vAll" onClick={toggle}>View All</button>
            </div>            
            <h5 className="annTitle">Announcements &amp; Latest Updates</h5>
          </div>
          <Slider {...settings}>
            {annList.map(itm => {
              return (
                <div>
                    <div className="p-4">
                      <div className="annItemWrap">
                        <span className={`anIco ${itm.icon}`}></span>
                        <h6 className="m-0">{itm.title}</h6>
                        <p className="mb-3">{formatDate(itm.edit_date)}</p>
                        <p>{itm.description}</p>
                        <div className="pt-3"><a href={itm.btn_link} target="_blank" className="ylowBtn">{itm.btn_name}</a></div>
                      </div>
                    </div>                
                </div>
              )
            } )}
          </Slider>
        </div>
      </div>
      
      {isOpened && (
          <div className='popOuter announce'>
              <div className='popIn'>
                  <button className='closeBtn' title='Close' id="cls_0" onClick={toggle}><HighlightOffIcon /></button>
                  <h5 className="annTitle mb-4">Announcements &amp; Latest Updates</h5>
                  <div className='popInCont'>                    
                    {annList.map(itm => {
                        return (
                          <div className="annItemWrap">
                              <span className={`anIco ${itm.icon}`}></span>
                              <h6 className="m-0">{itm.title}</h6>
                              <p className="mb-3">{formatDate(itm.edit_date)}</p>
                              <p>{itm.description}</p>
                              <div className="pt-3"><a href={itm.btn_link} target="_blank" className="ylowBtn">{itm.btn_name}</a></div>
                          </div>
                        )
                      } )}
                  </div>                                            
              </div>
          </div>
      )}

    </div>
    
  );
};
export default Home;
