import React, { useState, useEffect } from 'react';
import Footer from '../../Footer';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import BJF12A from './12A';
import BJF80G from './80G';
import BS202021 from './bs-2020-21';
import BS202122 from './bs-2021-22';
import BJFFC42012 from './FC4-2012';
import BJFFC42014 from './FC4-2014';
import BJFFC6 from './FC6';
import BS202223 from './bs-2022-23';

function StatutoryInfo() {

    const [isOpened, setIsOpened] = useState([false, false, false, false, false, false, false, false]);
    function toggle(e) {
        let id = parseInt(e.target.id.split('_')[1]);
        let dummy = [...isOpened];
        for (let i = 0; i < dummy.length; i++) {
            if (i == id) {
                dummy[id] = !dummy[id]
            }
            else {
                dummy[i] = false
            }
        }

        setIsOpened(dummy);

    }



    useEffect(() => {
        document.body.className = 'whiteHdr';
        return () => { document.body.className = ''; }
    });

    console.log(isOpened)

    return (
        <div className='inrPageTopPad statsInfo'>

            <section className='contLayout text-primary py-3 py-lg-5'>
                <div className='container-lg'>
                    <h1 className='mb-2'>Statutory Information</h1>
                    <p>Bhai Jaitajee Foundation is registered under Foreign Contribution Regulation Act (FCRA), 2010 | Registration Number : 291420092</p>

                    <div className='py-3 statInfoBtn text-center'>
                        <div className='row g-4'>
                            <div className='col-12 col-sm-6 col-lg-4'>
                                <button type='button' className='btn btn-primary rounded-pill' id='btn_0' onClick={toggle}>Balance Sheet 2022-23</button>
                                {isOpened[0] && (
                                    <div className='popOuter'>
                                        <div className='popIn'>
                                            <button className='closeBtn' title='Close' id="cls_0" onClick={toggle}><HighlightOffIcon /></button>
                                            <div className='popInCont'>
                                                <BS202223 />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-12 col-sm-6 col-lg-4'>
                                <button type='button' className='btn btn-primary rounded-pill' id='btn_1' onClick={toggle}>Balance Sheet 2021-22</button>
                                {isOpened[1] && (
                                    <div className='popOuter'>
                                        <div className='popIn'>
                                            <button className='closeBtn' title='Close' id="cls_1" onClick={toggle}><HighlightOffIcon /></button>
                                            <div className='popInCont'>
                                                <BS202122 />

                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-12 col-sm-6 col-lg-4'>
                                <button type='button' className='btn btn-primary rounded-pill' id='btn_2' onClick={toggle}>Balance Sheet 2020-21</button>
                                {isOpened[2] && (
                                    <div className='popOuter'>
                                        <div className='popIn'>
                                            <button className='closeBtn' title='Close' id="cls_2" onClick={toggle}><HighlightOffIcon /></button>
                                            <div className='popInCont'>
                                                <BS202021 />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-12 col-sm-6 col-lg-4'>
                                <button type='button' className='btn btn-primary rounded-pill' id='btn_3' onClick={toggle}>80G Renewal 2022</button>
                                {isOpened[3] && (
                                    <div className='popOuter'>
                                        <div className='popIn'>
                                            <button className='closeBtn' title='Close' id="cls_3" onClick={toggle}><HighlightOffIcon /></button>
                                            <div className='popInCont'>
                                                <BJF80G />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-12 col-sm-6 col-lg-4'>
                                <button type='button' className='btn btn-primary rounded-pill' id='btn_4' onClick={toggle}>12A Renewal 2022</button>
                                {isOpened[4] && (
                                    <div className='popOuter'>
                                        <div className='popIn'>
                                            <button className='closeBtn' title='Close' id="cls_4" onClick={toggle}><HighlightOffIcon /></button>
                                            <div className='popInCont'>
                                                <BJF12A />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className='col-12 col-sm-6 col-lg-4'>
                                <button type='button' className='btn btn-primary rounded-pill' id='btn_5' onClick={toggle}>FCRA Form FC-6</button>
                                {isOpened[5] && (
                                    <div className='popOuter'>
                                        <div className='popIn'>
                                            <button className='closeBtn' title='Close' id="cls_5" onClick={toggle}><HighlightOffIcon /></button>
                                            <div className='popInCont'>
                                                <BJFFC6 />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-12 col-sm-6 col-lg-4'>
                                <button type='button' className='btn btn-primary rounded-pill' id='btn_6' onClick={toggle}>Form FC-4 (2014)</button>
                                {isOpened[6] && (
                                    <div className='popOuter'>
                                        <div className='popIn'>
                                            <button className='closeBtn' title='Close' id="cls_6" onClick={toggle}><HighlightOffIcon /></button>
                                            <div className='popInCont'>
                                                <BJFFC42014 />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-12 col-sm-6 col-lg-4'>
                                <button type='button' className='btn btn-primary rounded-pill' id='btn_7' onClick={toggle}>Form FC-4 (2012)</button>
                                {isOpened[7] && (
                                    <div className='popOuter'>
                                        <div className='popIn'>
                                            <button className='closeBtn' title='Close' id="cls_7" onClick={toggle}><HighlightOffIcon /></button>
                                            <div className='popInCont'>
                                                <BJFFC42012 />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default StatutoryInfo